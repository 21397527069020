.Back{
      background: url(../assets/e.png) no-repeat;
      width: 460px;
      background-size: 100% 100%;
      margin-top: 40px;
}
/*#signup{*/
/*      width: 250px !important;*/
/*}*/

.login-pf-header{
      margin-top: 125px;
      color: white;
}
.cnBox{
      margin-left: -120px;
}
.login-pf-header h1, h3{
      color: white;
}
.formBox{
      height: 71px;
      margin-top: 50px;

}
.formBox h4{
      color:white;
}
.formBoxWx{
      width: 100%;
      height: 34px;
      border: 1px solid white;
      color: white;
}
.formBox a{
      display: inline-block;
      margin-top: -3px;
}
.content-left {
      background-image: url(../assets/d.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 500px;
      width: 558px;
      margin-top: 90px;
}
.content-left {
      transform: rotate(360deg);
      animation: rotation 15s linear infinite;
      -moz-animation: rotation 15s linear infinite;
      -webkit-animation: rotation 15s linear infinite;
      -o-animation: rotation 15s linear infinite;
}

@keyframes rotation {
      from {
            transform: rotate(0deg);
      }
      to {
           transform: rotate(360deg);
      }
}
.loginG{
      text-align: center;
      font-size: 29px;
      color: white;
      position: relative;
      left: 50%;
      margin-left: -79px;
      top: 20px;
}
.kc-page-title{
      margin-left:100px;
}

@media (max-width: 1024px){
      #signup{
            width: 500px !important;
            margin-left: -100px;
      }

      .cnBox{
            margin-left: 0px;
      }
      .content-left {
            position: absolute;
            z-index: -1;
            left: 50%;
            margin-left: -279px;
      }
      .loginG{
            display: none;
      }
}
@media (max-width: 767px){
      #signup{
            width: 250px !important;
            margin-left:0px;
      }

      .cnBox{
            margin-left:0px;
      }
      .content-left {
            position: absolute;
            z-index: -1;
            left: 50%;
            margin-left: -279px;
      }
      .loginG{
            display: none;
      }
      .kc-page-title{
            margin-left: 0px;
      }
}
.ant-form-item-label > label{
      color: white !important;
}
.ant-checkbox-wrapper span{
      color: white;
}
.ant-form-item-control-input-content{
      color: white;
}

.my-loading-container{
      position: absolute;
      left: 50%;
      top: 50%;
      bottom: 0;
      right: 0;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #f0ebeb;
      width: 100%;
      height: 100%;
      cursor: pointer;
}
